html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: 'PingFangSC-Light', 'PingFang SC', 'STHeitiSC-Light', 'Helvetica-Light', 'Arial', 'sans-serif';
}


$fontSize: 37.5;

@function px2rem($px) {
    @return ($px / $fontSize)+rem
}

;

@mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

// 最外层盒子样式
%out-box {
    //   width: (1920rem / 100);
    position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
    width: (1200rem / 100);
    margin: 0 auto;
}

.resources-bookdetail-bg {
    @extend %out-box;

    .resources-bookdetail {
        @extend %inside-box;

        .read-wrapper {
            .read {
                body {
                    width: 100%;
                    height: 100%;
                    column-width: 100% !important;
                }



                p {
                    width: 100% !important;
                }
            }

            .mask {
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 100;

                .left {
                    width: px2rem(100)
                }

                .right {
                    width: px2rem(100)
                }

                .center {
                    flex: 1;
                }
            }
        }
    }
}